import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

//MenuManagement
import CategoryMenu from "../pages/MenuManagement/CategoryMenu";
import Menus from "../pages/MenuManagement/Menus";
import Submenus from "../pages/MenuManagement/Submenu";

//HomeAppSettings
import BannerSettings from "../pages/HomeMenu/BannerSettings";
import PembelianSettings from "../pages/HomeMenu/PembelianSettings";
import PembayaranSettings from "../pages/HomeMenu/PembayaranSettings";
import PemesananSettings from "../pages/HomeMenu/PemesananSettings";
import KindnessSettings from "../pages/HomeMenu/KindnessSettings";

//UsersManagement
import RoleSettings from "../pages/UsersManagement/RoleSettings";
import UserSettings from "../pages/UsersManagement/UserSettings";
import MobUserSettings from "../pages/MobUsersManagement/UserSettings";

//Masjid Settings
import MasjidSettings from "../pages/Masjid/MasjidSettings";
import HistoryInfaq from "../pages/Masjid/HistoryInfaq";
import GaleriPenyaluranInfaq from "../pages/Masjid/GaleriPenyaluranInfaq";

import WebSettings from "../pages/WebSettings";

//Payment Management
import TransferBankSettings from "../pages/PaymentManagement/TransferBankSettings";

//Al-Qur'an Settings
import SurahSettings from "../pages/AlQuran/SurahSettings";
import AyatSettings from "../pages/AlQuran/AyatSettings";

//Product Settings
import PrepaidSettings from "../pages/Product/PrepaidSettings";
import PostpaidSettings from "../pages/Product/PostpaidSettings";
import LogoProductSettings from "../pages/Product/LogoProductSettings";

//Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

//History
import HistorySaldo from "../pages/Wallet/HistorySaldo";
import TopupServer from "../pages/SaldoServer/index";
import HistoryTopup from "../pages/Wallet/HistoryTopup";
import PendingTransaction from "../pages/Transaction/PendingTransaction";
import FailedTransaction from "../pages/Transaction/FailedTransaction";
import SuccessTransaction from "../pages/Transaction/SuccessTransaction";

import ErrorPage403 from "../pages/ErrorPage/Page403";

const userRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  //menu management
  { path: "/developer/category-menu-settings", component: <CategoryMenu /> },
  { path: "/developer/menu-settings", component: <Menus /> },
  { path: "/developer/submenu-settings", component: <Submenus /> },

  //home app settings
  { path: "/admin/banner-settings", component: <BannerSettings /> },
  { path: "/admin/menu-pembelian-settings", component: <PembelianSettings /> },
  { path: "/admin/menu-pembayaran-settings", component: <PembayaranSettings /> },
  { path: "/admin/menu-pemesanan-settings", component: <PemesananSettings /> },
  { path: "/admin/menu-kebaikan-settings", component: <KindnessSettings /> },

  //users management
  { path: "/developer/role-settings", component: <RoleSettings /> },
  { path: "/developer/user-settings", component: <UserSettings /> },
  { path: "/admin/user-settings", component: <MobUserSettings /> },

  //masjid settings
  { path: "/admin/masjid-settings", component: <MasjidSettings /> },
  { path: "/admin/history-infaq-masjid", component: <HistoryInfaq /> },
  { path: "/admin/galeri-penyaluran-infaq", component: <GaleriPenyaluranInfaq /> },

  { path: "/developer/web-settings", component: <WebSettings /> },

  //payment management
  { path: "/admin/transfer-bank-settings", component: <TransferBankSettings /> },

  //al-qur'an settings
  { path: "/admin/surah-settings", component: <SurahSettings /> },
  { path: "/admin/ayat-settings", component: <AyatSettings /> },

  //product
  { path: "/admin/prepaid-product-settings", component: <PrepaidSettings /> },
  { path: "/admin/postpaid-product-settings", component: <PostpaidSettings /> },
  { path: "/admin/logo-product-settings", component: <LogoProductSettings /> },

  //profile
  { path: "/profile", component: <UserProfile /> },

  //history
  { path: "/admin/history-saldo", component: <HistorySaldo /> },
  { path: "/admin/topup-server", component: <TopupServer /> },
  { path: "/admin/history-topup", component: <HistoryTopup /> },
  { path: "/admin/pending-transaction", component: <PendingTransaction /> },
  { path: "/admin/failed-transaction", component: <FailedTransaction /> },
  { path: "/admin/success-transaction", component: <SuccessTransaction /> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/panel/dashboard" /> },
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/error-403", component: <ErrorPage403 /> },
];

export { userRoutes, authRoutes };
