import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_transaction = (status, size, page, search) => {
    return axiosApi
        .get(API_URL + `get_transaction?status=${status}&limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const approve_pending = (param) => {
    return axiosApi
        .put(API_URL + "approve_pending_transaction/" + param, "", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};


const pendingTransaction = {
    all_transaction,
    approve_pending,
    API_URL,
};

export default pendingTransaction;