import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, Form, Label } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import config from "../../services/ConfigService"
import successTransaction from "../../services/Transaction"
import { useFormik } from "formik";
import { ModalConfirmation, ModalInfo } from "../../components/Common/ModalCustoms";

const SuccessTransaction = () => {
    const [list_success, setListSuccess] = useState([])
    const [view_config, setViewConfig] = useState("");
    const [edit_config, setEditConfig] = useState("");
    const [view_modal, setview_modal] = useState(false)
    const [edit_modal, setedit_modal] = useState(false)
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (size, page, search) => {
        try {
            var no = 1;
            const result = await successTransaction.all_transaction(4, size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                amount: numberFormat(item.amount),
                status: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.status)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.status)}
                    </span>
                ),
                created_date: Moment(item.created_date).utc().format('yyyy-MM-DD hh:mm:ss'),
                updated_date: Moment(item.updated_date).utc().format('yyyy-MM-DD hh:mm:ss'),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListSuccess(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 4:
                return "success"
            default:
                return "secondary"
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 4:
                return "Success"
            default:
                return "Unknown"
        }
    };

    const columns = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '6%',
        },
        {
            name: 'Buyer Trx ID',
            selector: row => row.ref_id,
            sortable: true,
            width: '11%',
        },
        {
            name: 'Transaction',
            selector: row => row.transaction_name,
            sortable: true,
            width: '11%',
        },
        {
            name: 'Destination',
            selector: row => row.destination_number,
            sortable: true,
            width: '14%',
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '10%',
        },
        {
            name: 'SKU',
            selector: row => row.buyer_sku_code,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Created Date',
            selector: row => row.created_date,
            sortable: true,
            width: '14%',
        },
        {
            name: 'Updated Date',
            selector: row => row.updated_date,
            sortable: true,
            width: '14%',
        }
    ];

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
    };

    const handleClickAdd = () => {
        setadd_modal(!add_modal)
    };

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "edit") {
            setedit_modal(false)
        } else {
            setadd_modal(false)
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickView = async (id) => {
        try {
            const data = await config.view_config(id)
            const updatedData = { ...data };
            setViewConfig(updatedData)
            setview_modal(!view_modal)
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickEdit = (id) => {
        config.view_config(id).then(
            (data) => {
                setedit_modal(!edit_modal)
                setEditConfig(data)
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    };

    const handleClickDeleteSelected = () => { };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_success)
    };

    document.title = `Success Transaction | ${webConfig[0].name} - ${webConfig[0].title}`;

    const validationEditType = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: edit_config.name,
            title: edit_config.title,
            favicon: edit_config.favicon,
            bg_login: edit_config.bg_login,
            copyright: edit_config.copyright,
            link_copyright: edit_config.link_copyright,
            version: edit_config.version
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                console.log(values)
                await config.edit_config(values)
                handleClickClose("edit")
                initData()
                window.location.reload()
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Transaction" breadcrumbItem="Success Transaction" />
                    <Row className="mb-3">
                        <ButtonContainer
                            isButtonAdd={false}
                            isButtonDelete={false}
                            handleClickAdd={handleClickAdd}
                            handleClickDeleteSelected={handleClickDeleteSelected}
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={edit_modal}
                    toggle={() => {
                        handleClickEdit()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Edit
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("edit")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationEditType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Name</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Enter name"
                                    value={validationEditType.values.name || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Title</Label>
                                <Input
                                    type="text"
                                    name="title"
                                    id="title"
                                    placeholder="Enter title"
                                    value={validationEditType.values.title || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Favicon</Label>
                                <Input
                                    type="text"
                                    name="favicon"
                                    id="favicon"
                                    placeholder="Enter favicon"
                                    value={validationEditType.values.favicon || ''}
                                    onChange={validationEditType.handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Background Login</Label>
                                <Input
                                    type="text"
                                    name="bg_login"
                                    id="bg_login"
                                    placeholder="Enter background login"
                                    value={validationEditType.values.bg_login || ''}
                                    onChange={validationEditType.handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Copyright</Label>
                                <Input
                                    type="text"
                                    name="copyright"
                                    id="copyright"
                                    placeholder="Enter copyright"
                                    value={validationEditType.values.copyright || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Link Copyright</Label>
                                <Input
                                    type="text"
                                    name="link_copyright"
                                    id="link_copyright"
                                    placeholder="Enter link copyright"
                                    value={validationEditType.values.link_copyright || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Version</Label>
                                <Input
                                    type="text"
                                    name="version"
                                    id="version"
                                    placeholder="Enter version"
                                    value={validationEditType.values.version || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("edit")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal >
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="4">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_config.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_config.name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Title
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_config.title}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Favicon
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_config.favicon}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Bakground Login
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_config.bg_login}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Copyright
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_config.copyright}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Link Copyright
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_config.link_copyright}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Version
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_config.version}
                            </Col>
                            <br />
                            <br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div >
        </React.Fragment >
    )
};

export default SuccessTransaction;
