import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import dashboard from "../../../services/DashboardService"
import { IMAGE_URL } from "../../../helpers/api_helper"

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [count, setCount] = useState("");
  const [data, setDataNotify] = useState([]);

  useEffect(() => {
    const controller = new AbortController()
    dashboard.getHistoryTopupNotify().then(
      (response) => {
        setCount(response.count);
        var notify = [];
        response.data.forEach(data => {
          notify.push(
            <Link to="#" className="text-reset notification-item">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">{data.users.full_name}</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      Melakukan topup sebesar <strong>{numberFormat(data.amount + data.unique_code)}</strong> dengan metode <strong>{data.method_category}</strong> ke <strong>{data.method_name}</strong>
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />&nbsp;{timeAgo(data.created_date)}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          )
        })
        setDataNotify(notify)
        return Promise.resolve();
      },
      (error) => {
        const errorCode = JSON.stringify(error.response.data.status);
        if (errorCode.toString() === "401") {
          window.location.replace('/panel/logout')
        } else if (errorCode.toString() === "403") {
          window.location.reload()
        }
      }
    )
    return () => controller.abort()
  }, [])

  const numberFormat = (value) =>
    new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
    }).format(value).replace(',00', '');

  function timeAgo(date) {
    const now = new Date();
    const past = new Date(date);
    const diffInSeconds = Math.floor((now - past) / 1000);

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInMonth = 2592000; // Assuming 30 days in a month

    if (diffInSeconds < secondsInMinute) {
      return 'baru saja';
    } else if (diffInSeconds < secondsInHour) {
      const minutes = Math.floor(diffInSeconds / secondsInMinute);
      return `${minutes} menit yang lalu`;
    } else if (diffInSeconds < secondsInDay) {
      const hours = Math.floor(diffInSeconds / secondsInHour);
      return `${hours} jam yang lalu`;
    } else if (diffInSeconds < secondsInMonth) {
      const days = Math.floor(diffInSeconds / secondsInDay);
      return `${days} hari yang lalu`;
    } else {
      const months = Math.floor(diffInSeconds / secondsInMonth);
      return `${months} bulan yang lalu`;
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <FeatherIcon icon="bell" className="icon-lg" />
          <span className="badge bg-danger rounded-pill">{count}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("List Topup Saldo User")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {data}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <a href="/panel/admin/history-topup"
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
            </a>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
