import React, { useEffect, useState } from "react";
import { Container, Button, Input, Row, Col, Modal, Form, Label } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import categoryMenu from "../../services/CategoryMenuService";
import { useFormik } from "formik";
import { ModalConfirm, ModalInfo } from "../../components/Common/ModalCustoms";

const CategoryMenu = () => {
    const [list_categorys, setListCategory] = useState([])
    const [view_category, setViewCategory] = useState("")
    const [view_role_category, setViewRoleCategory] = useState("")
    const [edit_category, setEditCategory] = useState("")
    const [view_modal, setview_modal] = useState(false)
    const [add_modal, setadd_modal] = useState(false)
    const [edit_modal, setedit_modal] = useState(false)
    const [filteredCategories, setFilteredCategories] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        var btnDelete = document.getElementById("btnDelete")
        btnDelete.style.visibility = "hidden";
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const initData = async (size, page, search) => {
        try {
            const result = await categoryMenu.all_category(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                role_name: item.roles.role_name,
                status: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.is_active)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.is_active)}
                    </span>
                ),
                created_date: Moment(item.created_date).utc().format('yyyy-MM-DD hh:mm:ss'),
                updated_date: Moment(item.updated_date).utc().format('yyyy-MM-DD hh:mm:ss'),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListCategory(formattedData)
            setFilteredCategories(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 2:
                return "success";
            case 3:
                return "danger";
            case 1:
                return "dark";
            default:
                return "secondary";
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 2:
                return "Active";
            case 3:
                return "Block";
            case 1:
                return "Not Active";
            default:
                return "Unknown";
        }
    };

    const checkedBoxesFormatter = (row) => {
        return (
            <div style={{ textAlign: "center" }}>
                <input type="checkbox" value={row.id} onClick={() => { handleClickCheckboxes(row.id) }} />
            </div>
        )
    };

    const rankFormatter = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(row.id) }} data-toggle="modal">
                    <i className="bx bx-show font-size-16 align-middle"></i>
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickEdit(row.id) }} data-toggle="modal">
                    <i className="bx bx-edit font-size-16 align-middle"></i>
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" className="btn btn-soft-danger waves-effect waves-light" onClick={() => { handleClickDeleteOne(row.id) }}>
                    <i className="bx bx-trash font-size-16 align-middle"></i>
                </button>
            </div>
        )
    };

    function handleClickCheckboxes() {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        var btnDelete = document.getElementById("btnDelete")
        if (checkedBoxes.length > 1) {
            btnDelete.style.visibility = 'visible';
        } else if (checkedBoxes.length < 1) {
            btnDelete.style.visibility = 'hidden';
        } else {
            btnDelete.style.visibility = 'hidden';
        }
    }

    const columns = [
        {
            name: '#',
            selector: row => row.checkbox,
            sortable: false,
            cell: checkedBoxesFormatter,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '5%',
        },
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '7%',
        },
        {
            name: 'Category Name',
            selector: row => row.category_menu_name,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Roles',
            selector: row => row.role_name,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Created Date',
            selector: row => row.created_date,
            sortable: true,
        },
        {
            name: 'Updated Date',
            selector: row => row.updated_date,
            sortable: true,
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
        document.getElementById("btnDelete").style.visibility = "hidden";
    };

    const handleClickAdd = () => {
        setadd_modal(!add_modal)
        validationAddType.resetForm()
    };

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "edit") {
            setedit_modal(false)
        } else {
            setadd_modal(false)
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickView = async (id) => {
        try {
            const data = await categoryMenu.view_category_menu(id)

            // Clone the data to avoid direct mutation
            const updatedData = { ...data };

            // Update status based on is_active
            if (data.is_active === 2) {
                updatedData.status = <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Active </span>;
            } else if (data.is_active === 3) {
                updatedData.status = <span className='badge-soft-danger me-1 badge badge-secondary badge-pill'> Block </span>;
            } else if (data.is_active === 1) {
                updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Not Active </span>;
            }

            setViewCategory(updatedData)
            setViewRoleCategory(data.roles)

            setview_modal(!view_modal)
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickEdit = (id) => {
        categoryMenu.view_category_menu(id).then(
            (data) => {
                setedit_modal(!edit_modal)
                setEditCategory(data)
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    };

    const handleClickDeleteSelected = () => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "category menu settings",
            () => processClickDeleteSelected(),
        )
    };

    const processClickDeleteSelected = async () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))

        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        }

        try {
            await categoryMenu.multi_delete_category_menu({ id: arrayCheck })
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                true
            )
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                ModalInfo(
                    "Error",
                    "An unexpected error occurred.",
                    "error",
                    false
                )
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickDeleteOne = (id) => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "category menu settings",
            () => processClickDeleteOne(id),
        )
    };

    const processClickDeleteOne = async (id) => {
        try {
            await categoryMenu.delete_category_menu(id)
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                true
            )
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFilteredCategories(list_categorys)
    };

    document.title = `Category Menu Settings | ${webConfig[0].name} - ${webConfig[0].title}`;

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            category_menu_name: "",
            roles_id: "",
            is_active: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await categoryMenu.add_category_menu(values)
                handleClickClose("add")
                window.location.reload()
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const validationEditType = useFormik({
        enableReinitialize: true,
        initialValues: {
            category_menu_name: edit_category.category_menu_name,
            roles_id: edit_category.roles_id,
            is_active: edit_category.is_active
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await categoryMenu.edit_category_menu(edit_category.id, values)
                handleClickClose("edit")
                window.location.reload()
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Setting Menu Panel" breadcrumbItem="Category Menu" />
                    <Row className="mb-3">
                        <ButtonContainer
                            isButtonAdd={true}
                            isButtonDelete={true}
                            handleClickAdd={handleClickAdd}
                            handleClickDeleteSelected={handleClickDeleteSelected}
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={"Category Name"}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filteredCategories}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={add_modal}
                    toggle={() => {
                        handleClickAdd()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Add
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("add")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Category Name</Label>
                                <Input
                                    type="text"
                                    name="category_menu_name"
                                    id="category_menu_name"
                                    placeholder="Enter category name"
                                    value={validationAddType.values.category_menu_name}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Roles</Label>
                                <Input
                                    type="select"
                                    name="roles_id"
                                    id="roles_id"
                                    value={validationAddType.values.roles_id}
                                    onChange={validationAddType.handleChange}
                                    required
                                >
                                    <option value="">Select Roles</option>
                                    <option value="1">Developer</option>
                                    <option value="2">Admin</option>
                                </Input>
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <Input
                                    type="select"
                                    name="is_active"
                                    id="is_active"
                                    value={validationAddType.values.is_active}
                                    onChange={validationAddType.handleChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Not Active</option>
                                    <option value="2">Active</option>
                                    <option value="3">Block</option>
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("add")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={edit_modal}
                    toggle={() => {
                        handleClickEdit()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Edit
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("edit")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationEditType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Category Name</Label>
                                <Input
                                    type="text"
                                    name="category_menu_name"
                                    id="category_menu_name"
                                    placeholder="Enter category name"
                                    value={validationEditType.values.category_menu_name || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Roles</Label>
                                <Input
                                    type="select"
                                    name="roles_id"
                                    id="roles_id"
                                    value={validationEditType.values.roles_id || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                >
                                    <option value="">Select Roles</option>
                                    <option value="1">Developer</option>
                                    <option value="2">Admin</option>
                                </Input>
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <Input
                                    type="select"
                                    name="is_active"
                                    id="is_active"
                                    value={validationEditType.values.is_active || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Not Active</option>
                                    <option value="2">Active</option>
                                    <option value="3">Block</option>
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Update</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("edit")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="3">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_category.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Category Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_category.category_menu_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Roles
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_role_category.role_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Status
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_category.status}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Created Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {Moment(view_category.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Updated Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {Moment(view_category.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment >
    )
};

export default CategoryMenu;
