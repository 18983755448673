import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_galeri_penyaluran = (size, page, search) => {
    return axiosApi
        .get(API_URL + `galeri_infaq?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_galeri_penyaluran = (bodyParam) => {
    return axiosApi
        .post(API_URL + "add_penyaluran_infaq", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const get_mosque = () => {
    return axiosApi
        .get(API_URL + "list_threshold_balance", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_galeri_infaq = (param) => {
    return axiosApi
        .get(API_URL + "galeri_infaq/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_galeri_infaq = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "galeri_infaq/" + param, bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const Galeri_infaq = {
    all_galeri_penyaluran,
    add_galeri_penyaluran,
    get_mosque,
    view_galeri_infaq,
    edit_galeri_infaq,
    API_URL,
};

export default Galeri_infaq;