import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import { IMAGE_URL } from "../../helpers/api_helper";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import History_topup from "../../services/HistoryTopup"
import Users from "../../services/MobUserService"
import { ModalConfirmation, ModalInfo } from "../../components/Common/ModalCustoms";
import { useFormik } from "formik"

const HistoryTopup = () => {
    const [list_history, setListHistory] = useState([])
    const [view_modal, setview_modal] = useState(false);
    const [view_topup, setViewTopup] = useState("");
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (size, page, search) => {
        try {
            const result = await History_topup.all_history(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                amount_format: numberFormat(item.amount),
                unique_code_format: numberFormat(item.unique_code),
                total: numberFormat(parseInt(item.amount) + parseInt(item.unique_code)),
                method: <img src={`${IMAGE_URL}${item.method_image}`} alt="" className="img-fluid" style={{ height: '30px' }} />,
                status_format: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.status)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.status)}
                    </span>
                ),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListHistory(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 5:
                return "danger"
            case 4:
                return "success"
            case 3:
                return "primary"
            case 2:
                return "dark"
            case 1:
                return "primary"
            case 0:
                return "dark"
            default:
                return "secondary"
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 5:
                return "cancel"
            case 4:
                return "Success"
            case 3:
                return "Process"
            case 2:
                return "Confirm"
            case 1:
                return "Payment"
            case 0:
                return "Wait for payment"
            default:
                return "Unknown"
        }
    };

    const columns = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '6%',
        },
        {
            name: 'Email',
            selector: row => row.users.email,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Phone',
            selector: row => row.users.phone,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Amount',
            selector: row => row.amount_format,
            sortable: true,
            width: '11%',
        },
        {
            name: 'Uniqe Code',
            selector: row => row.unique_code_format,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Total',
            selector: row => row.total,
            sortable: true,
            width: '11%',
        },
        {
            name: 'Method',
            selector: row => row.method,
            sortable: true,
            width: '12%',
        },
        {
            name: 'Status',
            selector: row => row.status_format,
            sortable: true,
            width: '8%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    function rankFormatter(cell, row, rowIndex, formatExtraData) {
        if (cell.status === 2 || cell.status === 1) {
            return (
                <div
                    style={{
                        textAlign: "left",
                    }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(cell.id) }} data-toggle="modal"><i className="bx bx-show font-size-16 align-middle"></i></button>
                        <UncontrolledDropdown style={{ marginLeft: '8px' }}>
                            <DropdownToggle
                                type="button"
                                className="btn btn-soft-success waves-effect waves-light"
                                style={{ boxShadow: 'none' }}
                            >
                                <i className="bx bx-check-circle font-size-16 align-middle"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem to="#" onClick={() => { handleClickApprove(cell.id, cell.users_id, cell.amount, cell.unique_code) }}>Approve</DropdownItem>
                                <DropdownItem to="#" onClick={() => { handleClickRejected(cell.id, cell.users_id) }}>Reject</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        textAlign: "left",
                    }}>
                    <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(cell.id) }} data-toggle="modal"><i className="bx bx-show font-size-16 align-middle"></i></button>
                </div>
            );
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        }
    }

    function handleClickApprove(id, users_id, amount, unique_code) {
        ModalConfirmation(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "tupup saldo users",
            () => processClickApprove({ id, users_id, amount, unique_code }),
        )
    }

    function handleClickRejected(id, users_id) {
        ModalConfirmation(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "tupup saldo users",
            () => processClickRejected({ id, users_id }),
        )
    }

    function processClickApprove(data) {
        Users.view_users(data.users_id).then(
            (dataa) => {
                var value = {};
                value["users_id"] = data.users_id;
                value["status"] = 4;
                value["balance"] = dataa.balance + data.amount + data.unique_code;
                value["nominal"] = data.amount + data.unique_code;
                value["saldo_awal"] = dataa.balance;
                value["saldo_akhir"] = dataa.balance + data.amount + data.unique_code;
                History_topup.approve(data.id, value).then(
                    (data) => {
                        ModalInfo(
                            "Approve!",
                            "Your data has been approved.",
                            "success",
                            false
                        )
                        initData(currentSize, currentPage, searchTerm)
                        return Promise.resolve()
                    },
                    (error) => {
                        const errorCode = JSON.stringify(error.response.data.status);
                        // console.log(errorCode)
                        if (errorCode.toString() === "401") {
                            window.location.replace('/panel/logout')
                        } else if (errorCode.toString() === "403") {
                            window.location.reload()
                        }
                    }
                )
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function processClickRejected(data) {
        Users.view_users(data.users_id).then(
            (dataa) => {
                var value = {};
                value["users_id"] = data.users_id;
                value["status"] = 5;
                History_topup.rejected(data.id, value).then(
                    (data) => {
                        ModalInfo(
                            "Approve!",
                            "Your data has been rejected.",
                            "success",
                            false
                        )
                        initData(currentSize, currentPage, searchTerm)
                        return Promise.resolve()
                    },
                    (error) => {
                        const errorCode = JSON.stringify(error.response.data.status);
                        // console.log(errorCode)
                        if (errorCode.toString() === "401") {
                            window.location.replace('/panel/logout')
                        } else if (errorCode.toString() === "403") {
                            window.location.reload()
                        }
                    }
                )
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    const handleClickView = async (id) => {
        try {
            const data = await History_topup.view_topup(id)

            // Clone the data to avoid direct mutation
            const updatedData = { ...data };
            var obj = updatedData;
            // Update status based on is_active
            Object.assign(obj, { email: obj.users.email, phone: obj.users.phone })
            Object.assign(obj, { images: <img src={IMAGE_URL + obj.method_image} alt="" className="img-fluid" style={{ height: '30px' }} /> })
            Object.assign(obj, { amount: numberFormat(obj.amount) })
            Object.assign(obj, { unique_code: numberFormat(obj.unique_code) })
            Object.assign(obj, { service_fee: numberFormat(obj.service_fee) })
            Object.assign(obj, { admin_fee: numberFormat(obj.admin_fee) })
            if (updatedData.status === 0) {
                Object.assign(obj, { status_topup: <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Wait for payment </span> });
            } else if (updatedData.status === 1) {
                Object.assign(obj, { status_topup: <span className='badge-soft-primary me-1 badge badge-secondary badge-pill'> Payment </span> });
            } else if (updatedData.status === 2) {
                Object.assign(obj, { status_topup: <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Confirm </span> });
            } else if (updatedData.status === 3) {
                Object.assign(obj, { status_topup: <span className='badge-soft-primary me-1 badge badge-secondary badge-pill'> Process </span> });
            } else if (updatedData.status === 4) {
                Object.assign(obj, { status_topup: <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Success </span> });
            }

            setViewTopup(updatedData)

            setview_modal(!view_modal)
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    // function handleClickView(id) {
    //     const numberFormat = (value) =>
    //         new Intl.NumberFormat('id-ID', {
    //             style: 'currency',
    //             currency: 'IDR'
    //         }).format(value).replace(',00', '');
    //     History_topup.view_topup(id).then(
    //         (data) => {
    //             var obj = data;
    //             setview_modal(!view_modal)
    //             removeBodyCss()
    //             if (data.is_active === 2) {
    //                 updatedData.status = <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Active </span>;
    //             } else if (data.is_active === 3) {
    //                 updatedData.status = <span className='badge-soft-primary me-1 badge badge-secondary badge-pill'> Coming Soon </span>;
    //             } else if (data.is_active === 1) {
    //                 updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Not Active </span>;
    //             }
    //             setViewTopup(data)
    //             return Promise.resolve()
    //         },
    //         (error) => {
    //             const errorCode = JSON.stringify(error.response.data.status);
    //             if (errorCode.toString() === "401") {
    //                 window.location.replace('/panel/logout')
    //             } else if (errorCode.toString() === "403") {
    //                 window.location.reload()
    //             }
    //         }
    //     )
    // }

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_history)
    };

    document.title = `History Topup Saldo User | ${webConfig[0].name} - ${webConfig[0].title}`;

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: "",
            bank: "",
            owner_name: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await SaldoServer.topup_server(values)
                handleClickClose("topup")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="History" breadcrumbItem="Topup Saldo User" />
                    <Row className="mb-3">
                        <Col md="10"></Col>
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="4">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Email
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.email}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Phone
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.phone}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Transaction Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.transaction_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Destination Number
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.destination_number}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Amount
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.amount}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Unique Code
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.unique_code}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Service Fee
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.service_fee}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Admin Fee
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.admin_fee}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Method Image
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.images}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Method Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.method_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Method Category
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.method_category}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Status
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_topup.status_topup}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Created Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_topup.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Updated Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_topup.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div >
        </React.Fragment >
    )
};

export default HistoryTopup;
