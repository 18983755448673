import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_history = (size, page, search) => {
    return axiosApi
        .get(API_URL + `history_infaq?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const History_infaq = {
    all_history,
    API_URL,
};

export default History_infaq;