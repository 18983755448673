import React, { useEffect, useState } from "react"
import { Container, InputGroup, Input, Row, Col, Modal, Form, Label } from "reactstrap"
import ButtonContainer from "../../components/Common/ButtonContainer"
import SearchContainer from "../../components/Common/SearchContainer"
import DataTableContainer from "../../components/Common/DataTableContainer"
import Pagination from "../../components/Common/Pagination"
import Moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import banner from "../../services/BannerService"
import galeri_infaq from "../../services/GaleriPenyaluranInfaq"
import { useFormik } from "formik"
import { ModalConfirm, ModalInfo } from "../../components/Common/ModalCustoms"
import { IMAGE_URL } from "../../helpers/api_helper"
import Flatpickr from "react-flatpickr"

const GaleriPenyaluranInfaq = () => {
    const [list_galeri_infaq, setListGaleriInfaq] = useState([])
    const [get_mosque, setMosque] = useState([]);
    const [view_galeri_infaq, setViewGaleriInfaq] = useState("")
    const [edit_galeri_infaq, setEditGaleriInfaq] = useState("")
    const [view_modal, setview_modal] = useState(false)
    const [add_modal, setadd_modal] = useState(false)
    const [edit_modal, setedit_modal] = useState(false)
    const [image, setImage] = useState("")
    const [imageEdit, setImageEdit] = useState("")
    const [price_format, setPriceFormat] = useState('');
    const [price, setPrice] = useState('');
    const [selectedDate, setSelectedDate] = useState("");
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (size, page, search) => {
        try {
            const result = await galeri_infaq.all_galeri_penyaluran(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                images: <img src={IMAGE_URL + item.image} alt="" className="img-fluid" />,
                nominal: numberFormat(item.nominal),
                tanggal_penyerahan: Moment(item.tanggal_penyerahan).utc().format('yyyy-MM-DD hh:mm:ss'),
                status: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.is_active)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.is_active)}
                    </span>
                ),
                created_date: Moment(item.created_date).utc().format('yyyy-MM-DD hh:mm:ss'),
                updated_date: Moment(item.updated_date).utc().format('yyyy-MM-DD hh:mm:ss'),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListGaleriInfaq(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 2:
                return "success"
            case 3:
                return "primary"
            case 1:
                return "dark"
            default:
                return "secondary"
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 2:
                return "Active"
            case 3:
                return "Coming Soon"
            case 1:
                return "Not Active"
            default:
                return "Unknown"
        }
    };

    const checkedBoxesFormatter = (row) => {
        return (
            <div style={{ textAlign: "center" }}>
                <input type="checkbox" value={row.id} onClick={() => { handleClickCheckboxes(row.id) }} />
            </div>
        )
    };

    const rankFormatter = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(row.id) }} data-toggle="modal">
                    <i className="bx bx-show font-size-16 align-middle"></i>
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickEdit(row.id) }} data-toggle="modal">
                    <i className="bx bx-edit font-size-16 align-middle"></i>
                </button>
            </div>
        )
    };

    const columns = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '6%',
        },
        {
            name: 'Masjid Name',
            selector: row => row.mosques.mosque_name,
            sortable: true,
            width: '17%',
        },
        {
            name: 'Judul',
            selector: row => row.judul,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Image',
            selector: row => row.images,
            width: '7%',
        },
        {
            name: 'Nominal',
            selector: row => row.nominal,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Tgl Penyerahan',
            selector: row => row.tanggal_penyerahan,
            sortable: true,
            width: '14%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
    };

    const handlePriceChange = (event) => {
        const input = event.target.value;
        const formattedPrice = formatCurrency(input);
        const replace = input.replace('Rp', '').replace(/\./g, '');
        validationAddType.setFieldValue('nominal', replace)
        setPriceFormat(formattedPrice);
    };

    const formatCurrency = (value) => {
        const number = value.replace(/[^0-9]/g, '');
        const length = number.length;
        let formattedNumber = '';

        if (length <= 3) {
            formattedNumber = number;
        } else if (length <= 6) {
            formattedNumber = `${number.substr(0, length - 3)}.${number.substr(length - 3)}`;
        } else if (length <= 9) {
            formattedNumber = `${number.substr(0, length - 6)}.${number.substr(length - 6, 3)}.${number.substr(length - 3)}`;
        } else {
            formattedNumber = `${number.substr(0, length - 9)}.${number.substr(length - 9, 3)}.${number.substr(length - 6, 3)}.${number.substr(length - 3)}`;
        }

        return `Rp ${formattedNumber}`;
    };

    const handleDateChange = (date) => {
        const convert = date[0];
        setSelectedDate(convert);
    };

    const handleClickAdd = () => {
        setadd_modal(!add_modal)
        validationAddType.resetForm()
        setImage("");
        galeri_infaq.get_mosque().then(
            (data) => {
                const numberFormat = (value) =>
                    new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR'
                    }).format(value).replace(',00', '');
                data.forEach(function (item) {
                    var obj = item;
                    Object.assign(obj, { nominal_format: numberFormat(item.balance) })
                })
                setMosque(data);
                return Promise.resolve();
            }, (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    };

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "edit") {
            setedit_modal(false)
        } else {
            setadd_modal(false)
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickView = async (id) => {
        const numberFormat = (value) =>
            new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR'
            }).format(value).replace(',00', '');
        try {
            const data = await galeri_infaq.view_galeri_infaq(id)

            // Clone the data to avoid direct mutation
            const updatedData = { ...data };
            updatedData.nominal = numberFormat(updatedData.nominal)
            updatedData.saldo_awal = numberFormat(updatedData.saldo_awal)
            updatedData.saldo_akhir = numberFormat(updatedData.saldo_akhir)

            // Update status based on is_active
            if (data.is_active === 2) {
                updatedData.status = <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Active </span>;
            } else if (data.is_active === 3) {
                updatedData.status = <span className='badge-soft-primary me-1 badge badge-secondary badge-pill'> Coming Soon </span>;
            } else if (data.is_active === 1) {
                updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Not Active </span>;
            }

            setViewGaleriInfaq(updatedData)

            setview_modal(!view_modal)
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickEdit = (id) => {
        validationEditType.resetForm()
        setImage("");
        galeri_infaq.view_galeri_infaq(id).then(
            (data) => {
                setedit_modal(!edit_modal)
                setEditGaleriInfaq(data)
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    };

    const handleClickDeleteSelected = () => { };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_galeri_infaq)
    };

    document.title = `Galeri Penyaluran Infaq | ${webConfig[0].name} - ${webConfig[0].title}`;

    const handleImageAddChange = (event) => {
        const file = event.target.files[0];
        setImage(file)
        validationAddType.setFieldValue('image', file)
    };

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            judul: "",
            mosque_id: "",
            description: "",
            image: "",
            nominal: "",
            tanggal_penyerahan: "",
        },
        onSubmit: async (values, { resetForm }) => {
            const arr = get_mosque.filter((data) => data.id = values.mosque_id)
            var mosque_name = ""
            var mosque_balance = ""
            const numberFormat = (value) =>
                new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR'
                }).format(value).replace(',00', '');
            arr.forEach((item) => {
                mosque_balance = item.balance
                mosque_name = item.mosque_name
            })

            const convert = selectedDate;
            const formattedDate = Moment(convert).format("YYYY-MM-DD");
            if (parseInt(values.nominal) > parseInt(mosque_balance)) {
                ModalInfo(
                    "Error",
                    "Nominal tidak boleh lebih besar dari saldo infaq masjid!\nSaldo infaq " + mosque_name + " adalah sebesar : " + numberFormat(mosque_balance),
                    "error",
                    false
                )
            } else {
                const formData = new FormData()
                formData.append('judul', values.judul)
                formData.append('mosque_id', values.mosque_id)
                formData.append('description', values.description)
                formData.append('image', values.image)
                formData.append('nominal', values.nominal)
                formData.append('tanggal_penyerahan', formattedDate)
                try {
                    await galeri_infaq.add_galeri_penyaluran(formData)
                    handleClickClose("add")
                    initData(currentSize, currentPage, searchTerm)
                    resetForm()
                } catch (error) {
                    const errorCode = JSON.stringify(error.response?.data?.status)
                    if (errorCode === "401") {
                        window.location.replace('/panel/logout')
                    } else if (errorCode === "403") {
                        window.location.reload()
                    } else {
                        console.error('An unexpected error occurred:', error)
                    }
                }
            }
        }
    })

    const handleImageEditChange = (event) => {
        const file = event.target.files[0];
        setImageEdit(file)
        validationEditType.setFieldValue('image', file)
    };

    const validationEditType = useFormik({
        enableReinitialize: true,
        initialValues: {
            judul: edit_galeri_infaq.judul,
            image: edit_galeri_infaq.image,
            image_path: edit_galeri_infaq.image,
            description: edit_galeri_infaq.description,
            tanggal_penyerahan: edit_galeri_infaq.tanggal_penyerahan
        },
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData()
            formData.append('judul', values.judul)
            formData.append('image', imageEdit)
            formData.append('image_path', values.image_path)
            formData.append('description', values.description)
            formData.append('tanggal_penyerahan', values.tanggal_penyerahan)
            try {
                await galeri_infaq.edit_galeri_infaq(edit_galeri_infaq.id, formData)
                handleClickClose("edit")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Masjid" breadcrumbItem="Galeri Penyaluran Infaq" />
                    <Row className="mb-3">
                        <ButtonContainer
                            isButtonAdd={true}
                            isButtonDelete={false}
                            handleClickAdd={handleClickAdd}
                            handleClickDeleteSelected={handleClickDeleteSelected}
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={add_modal}
                    toggle={() => {
                        handleClickAdd()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Add
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("add")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Judul</Label>
                                <Input
                                    type="text"
                                    name="judul"
                                    id="judul"
                                    placeholder="Enter judul"
                                    value={validationAddType.values.judul}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Masjid</Label>
                                <Input
                                    type="select"
                                    name="mosque_id"
                                    id="mosque_id"
                                    value={validationAddType.values.mosque_id || ''}
                                    onChange={validationAddType.handleChange}
                                    required
                                >
                                    <option value="">Select mssjid</option>
                                    {get_mosque.map((data, index) => (
                                        <option value={data.id} key={index}>{data.mosque_name} - {data.nominal_format}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="mb-3">
                                {image && <img className="preview" src={URL.createObjectURL(image)} alt="preview" style={{ height: "50px" }} />}<br /><br />
                                <Label className="form-label">Image</Label>
                                <Input
                                    type="file"
                                    name="image"
                                    id="image"
                                    placeholder="Enter image"
                                    onChange={handleImageAddChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Description</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    placeholder="Enter description"
                                    value={validationAddType.values.description}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Nominal</Label>
                                <Input
                                    type="text"
                                    name="nominal"
                                    id="nominal"
                                    placeholder="Enter nominal"
                                    value={price}
                                    hidden
                                />
                                <Input
                                    type="text"
                                    name="nominal_format"
                                    id="nominal_format"
                                    placeholder="Enter nominal"
                                    value={price_format}
                                    onChange={handlePriceChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label>Tanggal Penyerahan</Label>
                                <InputGroup>
                                    <Flatpickr
                                        name="tanggal_penyerahan"
                                        className="form-control d-block"
                                        placeholder="dd/mm/yyyy"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        options={{
                                            altInput: true,
                                            altFormat: "d/m/Y",
                                            dateFormat: "d/m/Y"
                                        }}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("add")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={edit_modal}
                    toggle={() => {
                        handleClickEdit()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Edit
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("edit")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationEditType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Judul</Label>
                                <Input
                                    type="text"
                                    name="judul"
                                    id="judul"
                                    placeholder="Enter judul"
                                    value={validationEditType.values.judul}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <Input
                                type="hidden"
                                name="image_path"
                                id="image_path"
                                value={validationEditType.values.image_path || ''}
                                onChange={validationEditType.handleChange}
                                required
                            />
                            <div className="mb-3">
                                {(imageEdit && <img className="preview" src={URL.createObjectURL(imageEdit)} alt="preview" style={{ height: "50px" }} />) || (validationEditType.values.image && <img className="preview" src={IMAGE_URL + validationEditType.values.image} alt="preview" style={{ height: "50px" }} />)}<br /><br />
                                <Label className="form-label">Image</Label>
                                <Input
                                    type="file"
                                    name="image"
                                    id="image"
                                    placeholder="Enter image"
                                    onChange={handleImageEditChange}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Description</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    placeholder="Enter description"
                                    value={validationEditType.values.description || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label>Tanggal Penyerahan</Label>
                                <Flatpickr
                                    name="tanggal_penyerahan"
                                    className="form-control d-block"
                                    placeholder="dd/mm/yyyy"
                                    value={validationEditType.values.tanggal_penyerahan}
                                    onChange={handleDateChange}
                                    options={{
                                        altInput: true,
                                        altFormat: "d/m/Y",
                                        dateFormat: "d/m/Y"
                                    }}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Update</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("edit")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="4">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_galeri_infaq.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Judul
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_galeri_infaq.judul}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Image
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                <img src={IMAGE_URL + view_galeri_infaq.image} alt="" className="img-fluid" />
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Description
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_galeri_infaq.description}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Nominal
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_galeri_infaq.nominal}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Saldo Awal
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_galeri_infaq.saldo_awal}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Saldo Akhir
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_galeri_infaq.saldo_akhir}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Tanggal Penyerahan
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_galeri_infaq.tanggal_penyerahan).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Created Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_galeri_infaq.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Updated Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_galeri_infaq.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment >
    )
};

export default GaleriPenyaluranInfaq;
