import React, { useEffect, useState, useRef } from "react";
import { Container, Input, Row, Col, Modal, Form, Label, Toast, ToastHeader, ToastBody } from "reactstrap";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import { IMAGE_URL } from "../../helpers/api_helper";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SaldoServer from "../../services/SaldoServerService"
import { useFormik } from "formik"
import logo from "../../assets/images/logo-sm.png";

const TopupServer = () => {
    const [toast, setToast] = useState(false);
    const [list_history, setListHistory] = useState([])
    const [list_bank, setListBank] = useState([]);
    const [topup_modal, settopup_modal] = useState(false);
    const [bank_name, setBankName] = useState("");
    const [owner_name, setOwnerName] = useState("");
    const [view_topup, setViewTopup] = useState("");
    const [view_bank, setViewBank] = useState("");
    const [view_modal, setview_modal] = useState(false);
    const regInput = React.useRef();
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    const toggleToast = () => {
        setToast(!toast);
    };

    useEffect(() => {
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (size, page, search) => {
        try {
            const result = await SaldoServer.all_saldo_server(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                amount: numberFormat(item.amount),
                saldo_awal: numberFormat(item.saldo_awal),
                saldo_akhir: numberFormat(item.saldo_akhir),
                status_format: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.status)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.status)}
                    </span>
                ),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListHistory(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const handleCopyAmount = (amount) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(amount)
                .then(() => {
                    toggleToast()
                    setTimeout(() => {
                        setToast(false);
                    }, 2000);
                })
        } else {
            fallbackCopyTextToClipboard(amount);
        }
    };

    const handleCopyNotes = (notes) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(notes)
                .then(() => {
                    toggleToast();
                    setTimeout(() => {
                        setToast(false);
                    }, 2000);
                })
                .catch(() => {
                    fallbackCopyTextToClipboard(notes);
                });
        } else {
            fallbackCopyTextToClipboard(notes);
        }
    };

    const handleCopyRek = (rekening) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(rekening)
                .then(() => {
                    toggleToast();
                    setTimeout(() => {
                        setToast(false);
                    }, 2000);
                })
                .catch(() => {
                    fallbackCopyTextToClipboard(notes);
                });
        } else {
            fallbackCopyTextToClipboard(notes);
        }
    };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            if (successful) {
                toggleToast();
                setTimeout(() => {
                    setToast(false);
                }, 2000);
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case "1":
                return "primary"
            case "0":
                return "dark"
            default:
                return "secondary"
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case "1":
                return "Success"
            case "0":
                return "Pending"
            default:
                return "Unknown"
        }
    };

    const columns = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '6%',
        },
        {
            name: 'Owner Name',
            selector: row => row.bank.account_name,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Bank Name',
            selector: row => row.bank.bank_name,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
            width: '12%',
        },
        {
            name: 'Saldo Awal',
            selector: row => row.saldo_awal,
            sortable: true,
            width: '12%',
        },
        {
            name: 'Saldo Akhir',
            selector: row => row.saldo_akhir,
            sortable: true,
            width: '13%',
        },
        {
            name: 'Status',
            selector: row => row.status_format,
            sortable: true,
            width: '8%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    function rankFormatter(cell, row, rowIndex, formatExtraData) {
        if (cell.status === "0") {
            return (
                <div
                    style={{
                        textAlign: "left",
                    }}>
                    <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickView(cell.id) }} data-toggle="modal"><i className="bx bx-file font-size-16 align-middle"></i></button>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        textAlign: "left",
                    }}>
                </div>
            );
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "topup") {
            settopup_modal(false)
        }
    }

    function handleClickTopup() {
        settopup_modal(!topup_modal);
        validationAddType.resetForm()
        setBankName("")
        setOwnerName("")
        listBankData();
    }

    function listBankData() {
        SaldoServer.get_list_bank().then(
            (data) => {
                setListBank(data);
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    const handleChange = (e) => {
        if (e.target.value !== "") {
            const { name, value } = e.target;
            validationAddType.setFieldValue(name, value)
            SaldoServer.get_one_bank(e.target.value).then(
                (data) => {
                    setBankName(data.bank_name)
                    setOwnerName(data.account_name)
                    validationAddType.setFieldValue('bank', data.bank_name)
                    validationAddType.setFieldValue('owner_name', data.account_name)
                    return Promise.resolve();
                },
                (error) => {
                    const errorCode = JSON.stringify(error.response.data.status);
                    if (errorCode.toString() === "401") {
                        window.location.replace('/panel/logout')
                    } else if (errorCode.toString() === "403") {
                        window.location.reload()
                    }
                }
            )
        } else {
            setBankName("")
            setOwnerName("")
        }
    }

    function handleClickView(id) {
        const numberFormat = (value) =>
            new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR'
            }).format(value).replace(',00', '');
        SaldoServer.get_topup(id).then(
            (data) => {
                var obj = data;
                setview_modal(!view_modal)
                removeBodyCss()
                Object.assign(obj, { amount_format: numberFormat(data.amount), })
                setViewTopup(data)
                setViewBank(data.bank)
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function handleProcessConfirm() {
        const id = regInput.current.value;
        SaldoServer.confirm(id).then(
            (data) => {
                setview_modal(false)
                initData(currentSize, currentPage, searchTerm)
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_history)
    };

    document.title = `History Topup Server H2H | ${webConfig[0].name} - ${webConfig[0].title}`;

    let rekening;
    let rekening_name;
    if (view_bank.bank_name === "Bank Central Asia (BCA)") {
        rekening = "6042888890";
        rekening_name = "PT DIGIFLAZZ INTERKONEKSI INDONESIA";
    } else if (view_bank.bank_name === "Bank Mandiri") {
        rekening = "1550009910111";
        rekening_name = "PT DIGIFLAZZ INTERKONEKSI INDONESIA";
    } else if (view_bank.bank_name === "Bank Rakyat Indonesia (BRI)") {
        rekening = "213501000291307";
        rekening_name = "DIGIFLAZZ INTERKONEK";
    } else if (view_bank.bank_name === "Bank Negara Indonesia (BNI)") {
        rekening = "1996888992";
        rekening_name = "PT DIGIFLAZZ INTERKONEKSI INDONESIA";
    }

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: "",
            bank: "",
            owner_name: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await SaldoServer.topup_server(values)
                handleClickClose("topup")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="History" breadcrumbItem="Topup Server H2H" />
                    <Row className="mb-3">
                        <Col md="10">
                            <button type="button" id="btnAdd" onClick={() => { handleClickTopup() }} data-toggle="modal" className="btn btn-success waves-effect btn-label waves-light"><i className="bx bx-plus label-icon"></i> Topup Saldo</button>
                        </Col>
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={topup_modal}
                    toggle={() => {
                        handleClickTopup()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Topup
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("topup")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Amount</Label>
                                <Input
                                    type="text"
                                    name="amount"
                                    id="amount"
                                    placeholder="Enter amount"
                                    value={validationAddType.values.amount}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Select Bank</Label>
                                <Input
                                    type="select"
                                    name="bank_id"
                                    id="bank_id"
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    {list_bank.map((data, index) => (
                                        <option value={data.id} key={index}>{data.bank_name}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Bank Name</Label>
                                <Input
                                    type="text"
                                    name="bank"
                                    id="bank"
                                    placeholder="Enter bank name"
                                    value={bank_name}
                                    disabled
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Owner Name</Label>
                                <Input
                                    type="text"
                                    name="owner_name"
                                    id="owner_name"
                                    placeholder="Enter owner name"
                                    value={owner_name}
                                    disabled
                                    required
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("topup")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    size="lg"
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Detail Bayar
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div style={{ textAlignVertical: "center", textAlign: "center" }}>
                            <span style={{ fontSize: "20px", fontWeight: "bold" }}>Isi Saldo Via {view_bank.bank_name}</span><br />
                            <span>Transaksi sukses, silahkan selesaikan pembayaran sesuai data di bawah</span><br /><br />
                            <img src={IMAGE_URL + view_bank.image} style={{ height: "40px" }} alt="" className="img-fluid" /><br /><br />
                        </div>
                        <br />
                        <div className="container">
                            <div className="row">
                                <Col xl="3">
                                    Jumlah Transfer
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    :
                                </Col>
                                <Col xl="7">
                                    {view_topup.amount_format}
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    <a href="#" onClick={() => handleCopyAmount(view_topup.amount)}><i className="bx bx-copy-alt font-size-20 align-middle" /></a>
                                </Col>
                            </div>
                            <br />
                            <div className="row">
                                <Col xl="3">
                                    Berita Transfer
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    :
                                </Col>
                                <Col xl="7">
                                    {view_topup.notes}
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    <a href="#" onClick={() => handleCopyNotes(view_topup.notes)} ><i className="bx bx-copy-alt font-size-20 align-middle" /></a>
                                </Col>
                            </div>
                            <br />
                            <div className="row">
                                <Col xl="3">
                                    No Rekning Transfer
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    :
                                </Col>
                                <Col xl="7">
                                    {rekening}
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    <a href="#" onClick={() => handleCopyRek(rekening)} ><i className="bx bx-copy-alt font-size-20 align-middle" /></a>
                                </Col>
                            </div>
                            <br />
                            <div className="row">
                                <Col xl="3">
                                    Nama Rekening
                                </Col>
                                <Col xl="1" style={{ textAlign: "right" }}>
                                    :
                                </Col>
                                <Col xl="7">
                                    {rekening_name}
                                </Col>
                                <input
                                    ref={regInput}
                                    name="id"
                                    value={view_topup.id || ''}
                                    className="form-control"
                                    type="hidden"
                                    required
                                />
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#f6e58d" }}>
                            <div style={{ margin: "20px" }}>
                                <br />
                                <span>Mohon untuk transfer sesuai dengan jumlah yang tertera (<b>Tanpa Pembulatan</b> atau tekan "copy"), untuk memudahkan verifikasi transfer deposit.</span><br /><br />
                                <span><b>Catatan :</b> Jumlah transfer (<b>{view_topup.amount_format}</b>) hanya berlaku hari ini. (<b>{Moment(view_topup.created_date).utc().format('yyyy-MM-DD')}</b>)</span><br /><br />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() =>
                                    handleProcessConfirm()
                                }
                            >
                                Konfirmasi Bayar
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div >
            <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1051 }}>
                <Toast isOpen={toast}>
                    <ToastHeader toggle={toggleToast}>
                        <img src={logo} className="me-2" height="18" alt="logo" />
                        Yaaay!
                    </ToastHeader>
                    <ToastBody style={{ background: "#FFFFFF" }}>
                        Teks berhasil disalin ke clipboard!
                    </ToastBody>
                </Toast>
            </div>
        </React.Fragment >
    )
};

export default TopupServer;
