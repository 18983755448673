import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, Form, Label } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import roles from "../../services/RoleService"
import users from "../../services/UserService"
import { Formik, useFormik } from "formik";
import { ModalConfirm, ModalInfo } from "../../components/Common/ModalCustoms";

const UserSettings = (text, number) => {
    const [list_user, setListUser] = useState([]);
    const [user_roles, setUserRoles] = useState([]);
    const [view_user, setViewUser] = useState("");
    const [edit_user, setEditUser] = useState("");
    const [view_modal, setview_modal] = useState(false)
    const [add_modal, setadd_modal] = useState(false)
    const [edit_modal, setedit_modal] = useState(false)
    const [passwordError, setPasswordError] = useState('');
    const [rolesRequired, setRolesRequired] = useState('');
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        var btnDelete = document.getElementById("btnDelete")
        btnDelete.style.visibility = "hidden";
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const initData = async (size, page, search) => {
        try {
            const result = await users.all_users(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                status: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.is_active)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.is_active)}
                    </span>
                ),
                created_date: Moment(item.created_date).utc().format('yyyy-MM-DD hh:mm:ss'),
                updated_date: Moment(item.updated_date).utc().format('yyyy-MM-DD hh:mm:ss'),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListUser(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 2:
                return "success";
            case 3:
                return "danger";
            case 1:
                return "dark";
            default:
                return "secondary";
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 2:
                return "Active";
            case 3:
                return "Block";
            case 1:
                return "Not Active";
            default:
                return "Unknown";
        }
    };

    const checkedBoxesFormatter = (row) => {
        return (
            <div style={{ textAlign: "center" }}>
                <input type="checkbox" value={row.id} onClick={() => { handleClickCheckboxes(row.id) }} />
            </div>
        )
    };

    const rankFormatter = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(row.id) }} data-toggle="modal" style={{ marginRight: "10px" }}>
                    <i className="bx bx-show font-size-16 align-middle"></i>
                </button>
                <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickEdit(row.id) }} data-toggle="modal" style={{ marginRight: "10px" }}>
                    <i className="bx bx-edit font-size-16 align-middle"></i>
                </button>
                <button type="button" className="btn btn-soft-danger waves-effect waves-light" onClick={() => { handleClickDeleteOne(row.id) }}>
                    <i className="bx bx-trash font-size-16 align-middle"></i>
                </button>
            </div>
        )
    };

    function handleClickCheckboxes() {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        var btnDelete = document.getElementById("btnDelete")
        if (checkedBoxes.length > 1) {
            btnDelete.style.visibility = 'visible';
        } else if (checkedBoxes.length < 1) {
            btnDelete.style.visibility = 'hidden';
        } else {
            btnDelete.style.visibility = 'hidden';
        }
    }

    const columns = [
        {
            name: '#',
            selector: row => row.checkbox,
            sortable: false,
            cell: checkedBoxesFormatter,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '5%',
        },
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '7%',
        },
        {
            name: 'First Name',
            selector: row => row.first_name,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Last Name',
            selector: row => row.last_name,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: '15%',
        },
        {
            name: 'phone',
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '9%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
        document.getElementById("btnDelete").style.visibility = "hidden";
    };

    const handleClickAdd = () => {
        setadd_modal(!add_modal)
        handleResetForm()
    };

    const handleResetForm = () => {
        validationAddType.resetForm()
        validationEditType.resetForm()
        setPasswordError('');
        setRolesRequired('');
    };

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "edit") {
            setedit_modal(false)
        } else {
            setadd_modal(false)
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickView = async (id) => {
        try {
            const data = await users.view_users(id)
            const updatedData = { ...data };
            if (data.is_active === 2) {
                updatedData.status = <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Active </span>;
            } else if (data.is_active === 3) {
                updatedData.status = <span className='badge-soft-danger me-1 badge badge-secondary badge-pill'> Block </span>;
            } else if (data.is_active === 1) {
                updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Not Active </span>;
            }
            var roles = [];
            var index = 0;
            data.roles.forEach(element => {
                index++
                roles.push(<div key={index}><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>{element}</div>)
            });
            setUserRoles(roles)
            setViewUser(updatedData)
            setview_modal(!view_modal)
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickEdit = (id) => {
        users.view_users(id).then(
            (data) => {
                setedit_modal(!edit_modal)
                setEditUser(data)
                handleResetForm()
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    };

    const handleClickDeleteSelected = () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        } else {
            ModalConfirm(
                "Are you sure?",
                "You won't be able to revert this!",
                "warning",
                "user settings",
                () => processClickDeleteSelected(),
            )
        }
    };

    const processClickDeleteSelected = async () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))

        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        }

        try {
            await users.multi_delete_users({ id: arrayCheck })
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm)
            document.getElementById("btnDelete").style.visibility = "hidden"
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                ModalInfo(
                    "Error",
                    "An unexpected error occurred.",
                    "error",
                    false
                )
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickDeleteOne = (id) => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "user settings",
            () => processClickDeleteOne(id),
        )
    };

    const processClickDeleteOne = async (id) => {
        try {
            await users.delete_users(id)
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm)
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_user)
    };

    document.title = `User Settings | ${webConfig[0].name} - ${webConfig[0].title}`;

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: "",
            last_name: "",
            username: "",
            password: "",
            password1: "",
            email: "",
            phone: "",
            is_active: "",
            roles: []
        },
        onSubmit: async (values, { resetForm }) => {
            if (values.password !== values.password1) {
                setPasswordError("Opss! Passwords do not match")
            } else if (values.roles.length === 0) {
                setRolesRequired("Roles can't be empty!")
            } else {
                setPasswordError("")
                setRolesRequired("")
                try {
                    await users.add_users(values)
                    handleClickClose("add")
                    initData(currentSize, currentPage, searchTerm)
                    resetForm()
                } catch (error) {
                    const errorCode = JSON.stringify(error.response?.data?.status)
                    if (errorCode === "401") {
                        window.location.replace('/panel/logout')
                    } else if (errorCode === "403") {
                        window.location.reload()
                    } else {
                        console.error('An unexpected error occurred:', error)
                    }
                }
            }

        }
    })

    const validationEditType = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: edit_user.first_name,
            last_name: edit_user.last_name,
            username: edit_user.username,
            password: "",
            password1: "",
            email: edit_user.email,
            phone: edit_user.phone,
            is_active: edit_user.is_active,
            roles: []
        },
        onSubmit: async (values, { resetForm }) => {
            console.log(values.roles)
            if (values.password !== values.password1 && values.password !== "" && values.password1 !== "") {
                setPasswordError("Opss! Passwords do not match")
            } else if (values.roles.length === 0) {
                setRolesRequired("Roles can't be empty!")
            } else {
                setPasswordError("")
                setRolesRequired("")
                try {
                    await users.edit_users(edit_user.id, values)
                    handleClickClose("edit")
                    initData(currentSize, currentPage, searchTerm)
                    resetForm()
                } catch (error) {
                    const errorCode = JSON.stringify(error.response?.data?.status)
                    if (errorCode === "401") {
                        window.location.replace('/panel/logout')
                    } else if (errorCode === "403") {
                        window.location.reload()
                    } else {
                        console.error('An unexpected error occurred:', error)
                    }
                }
            }
        }
    })

    const handleCheckboxChange = (event) => {
        var checkboxes = document.querySelectorAll('.form-check-input');
        var values = [];
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                values.push(checkbox.value);
            }
        });
        validationAddType.setFieldValue('roles', values)
        validationEditType.setFieldValue('roles', values)
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Users Management" breadcrumbItem="User Settings" />
                    <Row className="mb-3">
                        <ButtonContainer
                            isButtonAdd={true}
                            isButtonDelete={true}
                            handleClickAdd={handleClickAdd}
                            handleClickDeleteSelected={handleClickDeleteSelected}
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={add_modal}
                    toggle={() => {
                        handleClickAdd()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Add
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("add")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">First Name</Label>
                                <Input
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    placeholder="Enter first name"
                                    value={validationAddType.values.first_name}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Last Name</Label>
                                <Input
                                    type="text"
                                    name="last_name"
                                    id="last_name"
                                    placeholder="Enter last name"
                                    value={validationAddType.values.last_name}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Username</Label>
                                <Input
                                    type="text"
                                    name="username"
                                    id="username"
                                    placeholder="Enter username"
                                    value={validationAddType.values.username}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Password</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Enter password"
                                    value={validationAddType.values.password}
                                    onChange={validationAddType.handleChange}
                                    required
                                /><br />
                                <Input
                                    type="password"
                                    name="password1"
                                    id="password1"
                                    placeholder="Enter re-password"
                                    value={validationAddType.values.password1}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                                {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Enter email"
                                    value={validationAddType.values.email}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Phone</Label>
                                <Input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    placeholder="Enter phone"
                                    value={validationAddType.values.phone}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <Input
                                    type="select"
                                    name="is_active"
                                    id="is_active"
                                    value={validationAddType.values.is_active}
                                    onChange={validationAddType.handleChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Not Active</option>
                                    <option value="2">Active</option>
                                    <option value="3">Block</option>
                                </Input>
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Roles</Label>
                                <div className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="roles_developer"
                                        id="Developer"
                                        value="Developer"
                                        onChange={handleCheckboxChange}
                                        className="form-check-input"
                                    />
                                    <Label className="form-check-label" htmlFor="Developer">
                                        Developer
                                    </Label>
                                </div>
                                <div className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="roles_admin"
                                        id="Admin"
                                        value="Admin"
                                        onChange={handleCheckboxChange}
                                        className="form-check-input"
                                    />
                                    <Label className="form-check-label" htmlFor="Admin">
                                        Admin
                                    </Label>
                                </div>
                                {rolesRequired && <div style={{ color: 'red' }}>{rolesRequired}</div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("add")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={edit_modal}
                    toggle={() => {
                        handleClickEdit()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Edit
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("edit")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationEditType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">First Name</Label>
                                <Input
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    placeholder="Enter first name"
                                    value={validationEditType.values.first_name || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Last Name</Label>
                                <Input
                                    type="text"
                                    name="last_name"
                                    id="last_name"
                                    placeholder="Enter last name"
                                    value={validationEditType.values.last_name || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Username</Label>
                                <Input
                                    type="text"
                                    name="username"
                                    id="username"
                                    placeholder="Enter username"
                                    value={validationEditType.values.username || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Password</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Enter password"
                                    value={validationEditType.values.password || ''}
                                    onChange={validationEditType.handleChange}
                                /><br />
                                <Input
                                    type="password"
                                    name="password1"
                                    id="password1"
                                    placeholder="Enter re-password"
                                    value={validationEditType.values.password1 || ''}
                                    onChange={validationEditType.handleChange}
                                />
                                {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Enter email"
                                    value={validationEditType.values.email || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Phone</Label>
                                <Input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    placeholder="Enter phone"
                                    value={validationEditType.values.phone || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <Input
                                    type="select"
                                    name="is_active"
                                    id="is_active"
                                    value={validationEditType.values.is_active || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Not Active</option>
                                    <option value="2">Active</option>
                                    <option value="3">Block</option>
                                </Input>
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Roles</Label>
                                <div className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="roles_developer"
                                        id="Developer"
                                        value="Developer"
                                        onChange={handleCheckboxChange}
                                        className="form-check-input"
                                    />
                                    <Label className="form-check-label" htmlFor="Developer">
                                        Developer
                                    </Label>
                                </div>
                                <div className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="roles_admin"
                                        id="Admin"
                                        value="Admin"
                                        onChange={handleCheckboxChange}
                                        className="form-check-input"
                                    />
                                    <Label className="form-check-label" htmlFor="Admin">
                                        Admin
                                    </Label>
                                </div>
                                {rolesRequired && <div style={{ color: 'red' }}>{rolesRequired}</div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("edit")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal >
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="4">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_user.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                First Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_user.first_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Last Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_user.last_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Username
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_user.username}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Email
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_user.email}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Phone
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_user.phone}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Roles
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {user_roles}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Status
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {view_user.status}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Created Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {Moment(view_user.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Updated Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="6">
                                {Moment(view_user.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div >
        </React.Fragment >
    )
};

// UserSettings.defaultProps = {
//     text: 'User Settings',
//     number: 1,
// };

export default UserSettings;
