import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, Form, Label } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import History_infaq from "../../services/HistoryInfaq"

const HistoryInfaq = () => {
    const [list_history, setListHistory] = useState([])
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (size, page, search) => {
        try {
            const result = await History_infaq.all_history(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                nominal: numberFormat(item.nominal),
                saldo_awal: numberFormat(item.saldo_awal),
                saldo_akhir: numberFormat(item.saldo_akhir),
                infaq_date: Moment(item.infaq_date).utc().format('yyyy-MM-DD hh:mm:ss')
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListHistory(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const columns = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '70px',
        },
        {
            name: 'Full Name',
            selector: row => row.users.full_name,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Phone',
            selector: row => row.users.phone,
            sortable: true,
            width: '150px',
        },
        {
            name: 'Masjid/Mushola',
            selector: row => row.mosques.mosque_name,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Nominal',
            selector: row => row.nominal,
            sortable: true,
            width: '100px',
        },
        {
            name: 'Saldo Awal',
            selector: row => row.saldo_awal,
            sortable: true,
            width: '150px',
        },
        {
            name: 'Saldo Akhir',
            selector: row => row.saldo_akhir,
            sortable: true,
            width: '150px',
        },
        {
            name: 'Infaq Date',
            selector: row => row.infaq_date,
            sortable: true,
            width: '170px',
        }
    ];

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_history)
    };

    document.title = `History Infaq | ${webConfig[0].name} - ${webConfig[0].title}`;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Masjid" breadcrumbItem="History Infaq" />
                    <Row className="mb-3">
                        <ButtonContainer
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
            </div >
        </React.Fragment >
    )
};

export default HistoryInfaq;
