import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_masjid = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_mosque?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_mosque = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_mosque", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const delete_mosque = (param) => {
    return axiosApi
        .delete(API_URL + "mosque/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_mosque = (bodyParam) => {
    return axiosApi
        .post(API_URL + "mosque", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const sync_masjid = (bodyParam) => {
    return axiosApi
        .post(API_URL + "add_mosque", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_masjid = (param) => {
    return axiosApi
        .get(API_URL + "get_mosque/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_masjid = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "mosque/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const menu = {
    sync_masjid,
    all_masjid,
    multi_delete_mosque,
    delete_mosque,
    add_mosque,
    view_masjid,
    edit_masjid,
    API_URL,
};

export default menu;