import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const BASE_URL = "https://tupon.id/prod/"
const API_URL = BASE_URL + "cms/api/"
const API_URL_H2H = BASE_URL + "h2h/api/"
const IMAGE_URL = BASE_URL

const axiosApi = axios.create({
    baseURL: API_URL,
})

const axiosApiH2h = axios.create({
    baseURL: API_URL_H2H,
})

// axiosApi.defaults.headers.common["Authorization"] = token

// axiosApi.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// )

export async function get(url, config = {}) {
    return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
    return axiosApi
        .post(url, { ...data }, { ...config })
        .then(response => response.data)
}

export async function put(url, data, config = {}) {
    return axiosApi
        .put(url, { ...data }, { ...config })
        .then(response => response.data)
}

export async function del(url, config = {}) {
    return await axiosApi
        .delete(url, { ...config })
        .then(response => response.data)
}

export { axiosApi, axiosApiH2h, API_URL, API_URL_H2H, IMAGE_URL };
