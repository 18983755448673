import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, Form, Label } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
import { connect } from "react-redux"
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import roles from "../../services/RoleService"
import masjid from "../../services/MasjidService"
import { useFormik } from "formik";
import { ModalConfirm, ModalInfo } from "../../components/Common/ModalCustoms";

const LoadingContainer = () => <div>Loading...</div>

const MasjidSettings = props => {
    const selectedPlace = {}
    const [list_roles, setListRoles] = useState([])
    const [view_masjid, setViewMasjid] = useState("");
    const [edit_masjid, setEditMasjid] = useState("");
    const [view_modal, setview_modal] = useState(false)
    const [add_modal, setadd_modal] = useState(false)
    const [sync_modal, setsync_modal] = useState(false);
    const [edit_modal, setedit_modal] = useState(false)
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLatitude(position.coords.latitude)
            setLongitude(position.coords.longitude)
        })
        var btnDelete = document.getElementById("btnDelete")
        btnDelete.style.visibility = "hidden";
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const initData = async (size, page, search) => {
        try {
            const result = await masjid.all_masjid(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                status: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.is_active)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.is_active)}
                    </span>
                ),
                created_date: Moment(item.created_date).utc().format('yyyy-MM-DD hh:mm:ss'),
                updated_date: Moment(item.updated_date).utc().format('yyyy-MM-DD hh:mm:ss'),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListRoles(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const onMarkerDragEnd = (coord) => {
        const { latLng } = coord;
        const lat = latLng.lat().toString();
        const lng = latLng.lng().toString();

        const latSplit = lat.split(".");
        let lat1 = latSplit[0];
        let lat2 = latSplit[1].substring(0, 7);

        const lngSplit = lng.split(".");
        let lng1 = lngSplit[0];
        let lng2 = lngSplit[1].substring(0, 7);

        setLatitude(lat1 + "." + lat2);
        setLongitude(lng1 + "." + lng2);
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 2:
                return "success"
            case 3:
                return "primary"
            case 1:
                return "dark"
            default:
                return "secondary"
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 2:
                return "Active"
            case 3:
                return "Block"
            case 1:
                return "Not Active"
            default:
                return "Unknown"
        }
    };

    const changeAdd = (e) => {
        const { name, value } = e.target;
        validationAddType.setFieldValue(name, value)
    };

    const checkedBoxesFormatter = (row) => {
        return (
            <div style={{ textAlign: "center" }}>
                <input type="checkbox" value={row.id} onClick={() => { handleClickCheckboxes(row.id) }} />
            </div>
        )
    };

    const rankFormatter = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(row.id) }} data-toggle="modal">
                    <i className="bx bx-show font-size-16 align-middle"></i>
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickEdit(row.id) }} data-toggle="modal">
                    <i className="bx bx-edit font-size-16 align-middle"></i>
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" className="btn btn-soft-danger waves-effect waves-light" onClick={() => { handleClickDeleteOne(row.id) }}>
                    <i className="bx bx-trash font-size-16 align-middle"></i>
                </button>
            </div>
        )
    };

    function handleClickCheckboxes() {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        var btnDelete = document.getElementById("btnDelete")
        if (checkedBoxes.length > 1) {
            btnDelete.style.visibility = 'visible';
        } else if (checkedBoxes.length < 1) {
            btnDelete.style.visibility = 'hidden';
        } else {
            btnDelete.style.visibility = 'hidden';
        }
    }

    const columns = [
        {
            name: '#',
            selector: row => row.checkbox,
            sortable: false,
            cell: checkedBoxesFormatter,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '5%',
        },
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '6%',
        },
        {
            name: 'Nama Masjid',
            selector: row => row.mosque_name,
            sortable: true,
            width: '23%',
        },
        {
            name: 'Latitude',
            selector: row => row.latitude,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Longitude',
            selector: row => row.longitude,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '7%',
        },
        {
            name: 'Created Date',
            selector: row => row.created_date,
            sortable: true,
            width: '12%',
        },
        {
            name: 'Updated Date',
            selector: row => row.updated_date,
            sortable: true,
            width: '12%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
        document.getElementById("btnDelete").style.visibility = "hidden";
    };

    const handleClickAdd = () => {
        setadd_modal(!add_modal)
        validationAddType.resetForm()
    };

    const handleClickSync = () => {
        setsync_modal(!sync_modal)
    };

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "edit") {
            setedit_modal(false)
        } else if (action === "sync") {
            setsync_modal(false)
        } else {
            setadd_modal(false)
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickView = async (id) => {
        try {
            const data = await masjid.view_masjid(id)
            const updatedData = { ...data };
            if (data.is_active === 2) {
                updatedData.status = <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Active </span>;
            } else if (data.is_active === 1) {
                updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Not Active </span>;
            }
            setViewMasjid(updatedData)
            setview_modal(!view_modal)
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickEdit = (id) => {
        masjid.view_masjid(id).then(
            (data) => {
                setedit_modal(!edit_modal)
                setEditMasjid(data)
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    };

    const handleClickDeleteSelected = () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        } else {
            ModalConfirm(
                "Are you sure?",
                "You won't be able to revert this!",
                "warning",
                "mosque settings",
                () => processClickDeleteSelected(),
            )
        }
    };

    const processClickDeleteSelected = async () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))

        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        }

        try {
            await masjid.multi_delete_mosque({ id: arrayCheck })
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm)
            document.getElementById("btnDelete").style.visibility = "hidden"
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                ModalInfo(
                    "Error",
                    "An unexpected error occurred.",
                    "error",
                    false
                )
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickDeleteOne = (id) => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "mosque settings",
            () => processClickDeleteOne(id),
        )
    };

    const processClickDeleteOne = async (id) => {
        try {
            await masjid.delete_mosque(id)
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm)
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_roles)
    };

    document.title = `Masjid Settings | ${webConfig[0].name} - ${webConfig[0].title}`;

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            mosque_name: "",
            address: "",
            latitude: "",
            longitude: "",
            is_active: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await masjid.add_mosque(values)
                handleClickClose("add")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const validationEditType = useFormik({
        enableReinitialize: true,
        initialValues: {
            mosque_name: edit_masjid.mosque_name,
            address: edit_masjid.address,
            latitude: edit_masjid.latitude,
            longitude: edit_masjid.longitude,
            is_active: edit_masjid.is_active
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await masjid.edit_masjid(edit_masjid.id, values)
                handleClickClose("edit")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const validationSyncType = useFormik({
        enableReinitialize: true,
        initialValues: {
            latitude: "",
            longitude: "",
            radius: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await masjid.sync_masjid(values)
                handleClickClose("sync")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Masjid" breadcrumbItem="Masjid Settings" />
                    <Row className="mb-3">
                        <ButtonContainer
                            isButtonAdd={true}
                            isButtonDelete={true}
                            isButtonSync={true}
                            handleClickAdd={handleClickAdd}
                            handleClickSync={handleClickSync}
                            handleClickDeleteSelected={handleClickDeleteSelected}
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={add_modal}
                    toggle={() => {
                        handleClickAdd()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Add
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("add")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Masjid Name</Label>
                                <Input
                                    type="text"
                                    name="mosque_name"
                                    id="mosque_name"
                                    placeholder="Enter masjid name"
                                    value={validationAddType.values.mosque_name}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Address</Label>
                                <Input
                                    type="textarea"
                                    name="address"
                                    id="address"
                                    placeholder="Enter address"
                                    value={validationAddType.values.address}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Latitude</Label>
                                <Input
                                    type="text"
                                    name="latitude"
                                    id="latitude"
                                    placeholder="Enter latitude"
                                    value={validationAddType.values.latitude}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Longitude</Label>
                                <Input
                                    type="text"
                                    name="longitude"
                                    id="longitude"
                                    placeholder="Enter longitude"
                                    value={validationAddType.values.longitude}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <Input
                                    type="select"
                                    name="is_active"
                                    id="is_active"
                                    value={validationAddType.values.is_active || ''}
                                    onChange={validationAddType.handleChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Not Active</option>
                                    <option value="2">Active</option>
                                    <option value="3">Block</option>
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("add")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={edit_modal}
                    toggle={() => {
                        handleClickEdit()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Edit
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("edit")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationEditType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Masjid Name</Label>
                                <Input
                                    type="text"
                                    name="mosque_name"
                                    id="mosque_name"
                                    placeholder="Enter mosque name"
                                    value={validationEditType.values.mosque_name || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Address</Label>
                                <Input
                                    type="textarea"
                                    name="address"
                                    id="address"
                                    placeholder="Enter address"
                                    value={validationEditType.values.address || ''}
                                    onChange={validationEditType.handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Latitude</Label>
                                <Input
                                    type="text"
                                    name="latitude"
                                    id="latitude"
                                    placeholder="Enter latitude"
                                    value={validationEditType.values.latitude || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Longitude</Label>
                                <Input
                                    type="text"
                                    name="lognitude"
                                    id="longitude"
                                    placeholder="Enter longitude"
                                    value={validationEditType.values.longitude || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <Input
                                    type="select"
                                    name="is_active"
                                    id="is_active"
                                    value={validationEditType.values.is_active || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Not Active</option>
                                    <option value="2">Active</option>
                                    <option value="3">Block</option>
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("edit")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal >
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="4">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_masjid.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Masjid Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_masjid.mosque_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Type
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_masjid.type}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Address
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_masjid.address}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Latitude
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_masjid.latitude}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Longitude
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_masjid.longitude}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Status
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_masjid.status}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Created Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_masjid.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Updated Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_masjid.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={sync_modal}
                    toggle={() => {
                        handleClickSync()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Synchronize
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("sync")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationSyncType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Latitude</Label>
                                <Input
                                    type="text"
                                    name="latitude"
                                    id="latitude"
                                    placeholder="Enter latitude"
                                    value={validationSyncType.values.latitude || latitude}
                                    onChange={validationSyncType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Longitude</Label>
                                <Input
                                    type="text"
                                    name="longitude"
                                    id="longitude"
                                    placeholder="Enter longitude"
                                    value={validationSyncType.values.longitude || longitude}
                                    onChange={validationSyncType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Radius</Label>
                                <Input
                                    type="select"
                                    name="radius"
                                    id="radius"
                                    value={validationSyncType.values.radius || ''}
                                    onChange={validationSyncType.handleChange}
                                    required
                                >
                                    <option value="">Select Radius</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    <option value="1500">1500</option>
                                    <option value="2000">2000</option>
                                </Input>
                            </div>
                            <div className="mb-3">
                                <div
                                    id="gmaps-markers"
                                    className="gmaps"
                                    style={{ position: "relative" }}
                                >
                                    <Map
                                        google={props.google}
                                        zoom={14}
                                        style={{ width: "100%", height: "100%" }}
                                        initialCenter={{
                                            lat: latitude,
                                            lng: longitude,
                                        }}
                                        fullscreenControl={false}
                                        streetViewControl={false}
                                        mapTypeControl={false}
                                    >
                                        <Marker
                                            draggable={true}
                                            onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
                                        />
                                        <InfoWindow>
                                            <div>
                                                <h1>{selectedPlace.name}</h1>
                                            </div>
                                        </InfoWindow>
                                    </Map>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("sync")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
            </div >
        </React.Fragment >
    )
};

MasjidSettings.propTypes = {
    google: PropTypes.object
}

export default connect(
    null,
    {}
)(
    GoogleApiWrapper({
        apiKey: "AIzaSyBzQYwu26vWpIAg7XwUyMtVhe-DmYVBIVc",
        LoadingContainer: LoadingContainer,
        v: "3",
    })(MasjidSettings)
)
