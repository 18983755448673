import { axiosApiH2h, API_URL_H2H } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_postpaid_product = (size, page, search, status_product) => {
    return axiosApiH2h
        .get(API_URL_H2H + `list_product_postpaid?limit=${size}&page=${page}&search=${search}&status=${status_product}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const synchronize_data = (bodyParam) => {
    return axiosApiH2h
        .post(API_URL_H2H + "update_product_postpaid", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_postpaid = (param) => {
    return axiosApiH2h
        .get(API_URL_H2H + "get_product_postpaid/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_postpaid = (param, bodyParam) => {
    return axiosApiH2h
        .put(API_URL_H2H + "update_product_postpaid/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const postpaid = {
    all_postpaid_product,
    synchronize_data,
    view_postpaid,
    edit_postpaid,
    API_URL_H2H,
};

export default postpaid;